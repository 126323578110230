import Vue from "vue"

Vue.mixin({
	methods: {
		hasAuth(perm) {
			var authority = this.$store.state.menus.permList

			return authority.indexOf(perm) > -1
		},
		geturl(){
			return "http://139.196.160.96:9080"
		}
	}
})
