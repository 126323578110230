import axios from "axios";
import Element, { Message, MessageBox } from 'element-ui'
import router from "./router";
import store from "./store";
import ty from "./utils/ty";
axios.defaults.baseURL = ty.apiurl
const request = axios.create({
    timeout: 100000000,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})
request.interceptors.request.use(config => {
    config.headers['Authorization'] = localStorage.getItem("token") // 请求头带上token
    return config
})
request.interceptors.response.use(response => {
    console.log('======================')
    console.log(response)
    console.log('======================')
    const res = response.data
    if (res.code != 0) {
        console.log('123456789987654321')
        Message({
            message: res.msg,
            type: 'error',
            duration: 5 * 1000
        })
        // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
        if (res.code === 202 || res.code === 50012 || res.code === 50014 || res.code === 10000 || res.code === 401) {
            MessageBox.confirm(
                '你已被登出，可以取消继续留在该页面，或者重新登录',
                '确定登出',
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                store.dispatch('FedLogOut').then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                })
            })
        }
        return Promise.reject('error')
    } else {
        return response.data
    }
},
    error => {
        console.log(error.response.data.code)
        // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
        if (error.response.data.code ===1000) {
            MessageBox.confirm(
                '你已被登出，可以取消继续留在该页面，或者重新登录',
                error.response.data.msg,
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                store.dispatch('FedLogOut').then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                })
            })
        } else {
            Message({
                message: error,
                type: 'error',
                duration: 5 * 1000
            })
        }
        return Promise.reject(error)
    }
)
export default request
