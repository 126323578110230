<template>
    <el-container>
        <el-aside width="200px">
            <div class="indexMenu">
<!--                <img class="logoImg" src="../assets/images/logo.png" alt="">-->
                <p v-if="ty.apiurl=='https://yfs.hzjxsj.com/api'" class="loginTitle">永红物流后台(测试版)</p>
                <p class="loginTitle" v-else>永红物流后台管理系统</p>
            </div>
            <SideMenu></SideMenu>
        </el-aside>
        <el-container>
            <el-header style="height: 50px;">
                <div class="header-avatar block">
                    <el-avatar class="el-avatar" size="medium" :src="userInfo.avatar"></el-avatar>
                    <el-dropdown>
                    <span class="el-dropdown-link">
                        {{userInfo.username}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :underline="false">
                                <router-link :to="{name: 'UserCenter'}">个人中心</router-link>
                            </el-dropdown-item>
                            <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </el-header>
            <el-main>
                <Tabs></Tabs>
                <div style="margin: 0 15px;">
                    <router-view></router-view>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import SideMenu from "./inc/SideMenu";
    import Tabs from "./inc/Tabs";
    import ty from "../utils/ty";
    export default {
        name: "Home",
        components: {
            SideMenu,
            Tabs
        },
        data() {
            return {
                userInfo: {
                    id: '',
                    username: '',
                    avatar: ''
                }
            }
        },
        created() {
            this.getUserInfo()
        },
        methods: {
            getUserInfo() {
                this.$axios.post("/admin/user/userInfo").then(res => {
                    this.userInfo = res.data;
                })
            },
            logout() {
                this.$axios.post("/logout").then(res => {
                    localStorage.clear()
                    sessionStorage.clear()
                    this.$store.commit("resetState")
                    this.$router.push("/login")
                })
            }
        }

    }
</script>

<style scoped>
    .el-container{
        padding: 0;
        margin: 0;
        height: 100%;
    }
    .header-avatar{
        float: right;
        width: 120px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .el-dropdown-link {
        cursor: pointer;
    }
    .el-header{
        background-color: #FFFFFF;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .el-aside {
        background-color: #D3DCE6;
        color: #333;
        line-height: 200px;
    }

    .el-main {
        background-color: #F0F0F2;
        color: #333;
        padding: 0px;
    }
    .loginTitle {
        text-align: center;
        line-height: 30px;
        margin-bottom: 0;
        color: #FFFFFF;
    }
    .logoImg{
        width: 50%;
        margin: 0 auto;
        display: block;
    }
    .indexMenu{
        background-color:#545c64;
        padding-top: 10px;

    }
</style>
